import React, { useEffect, useRef, useState } from "react";

const GoogleMapComponent = ({ address = "서울시청", title = "", width = "100%", height = "200px" }) => {
    const [map, setMap] = useState(null);
    const ref = useRef();

    useEffect(() => {
        const geocoder = new (window as any).google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
            if (status === "OK") {
                const location = results[0].geometry.location;
                const newMap = new (window as any).google.maps.Map(ref.current, {
                    zoom: 16,
                });
                newMap.setCenter(location);
                new (window as any).google.maps.Marker({
                    position: location,
                    map: newMap,
                });
                setMap(newMap);
            } else {
                console.error("Geocode was not successful for the following reason: " + status);
            }
        });
    }, [])

    return (
        <div ref={ref} id="map" style={{
            width,
            height,
        }}></div>
    )
}

export default GoogleMapComponent;