import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import BasicArticle from "components/Article/Basic";
import Badges from "components/Badges";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DoctorInfo = ({ doctorInfo }) => {
    const navigate = useNavigate();

    if (_.isEmpty(doctorInfo)) {
        return <SpinnerMedium />
    }

    console.log("[DoctorInfo] doctorInfo", doctorInfo);

    return (
        <div>
            <div className="p-5">
                <div className="font-semibold">
                    연혁
                </div>
                <div className="mt-3">
                    <BasicArticle>
                        <div className="text-sm leading-normal">
                            {doctorInfo.description}
                        </div>
                    </BasicArticle>
                </div>
            </div>
            <div className="border-t border-gray-200"></div>
            <div className="p-5">
                <div className="font-semibold">
                    진료과목
                </div>
                <div className="mt-3">
                    <Badges
                        items={doctorInfo.specialties.map((sp) => sp.name)}
                        isRemovable={false}
                    />
                </div>
            </div>
            <div className="border-t border-gray-200"></div>
            <div className="p-5 cursor-pointer" onClick={() => {
                navigate(`/contents/clinic/${doctorInfo.clinic.id}`);
            }}>
                <div className="font-semibold">
                    소속병원
                </div>
                <div className="flex mt-3 items-center">
                    {!_.isEmpty(doctorInfo.clinic.profileImage) ?
                        <img
                            src={doctorInfo.clinic.profileImage}
                            alt="profileImage"
                            className="h-24 w-24 p-2 object-center rounded-full"
                        /> :
                        <div className="flex justify-center text-pink-300 size-24">
                            <div className="flex justify-center ring ring-inset ring-pink-300 ring-[6px] rounded-full size-20 self-center">
                                <BuildingOfficeIcon className="size-14 self-center" />
                            </div>
                        </div>
                    }
                    <div className="p-2">
                        <div className="text-lg font-bold self-center">
                            {doctorInfo.clinic.name}
                        </div>
                        <div className="text-sm text-gray-500 self-center">
                            {"서울 ・ 강남역"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorInfo;
