import { ReviewCard } from "common/pages/ClinicDetail/ClinicReviewList";
import Card from "components/Card";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";
import React, { useEffect, useState } from "react";


const DoctorReviewList = ({ doctorInfo }) => {
    console.log("[DoctorRevieList] doctorInfo", doctorInfo);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        if (_.isNil(doctorInfo.reviews)) {
            console.log("[DoctorRevieList] doctorInfo.reviews is empty");
            return;
        }
        setReviews(doctorInfo.reviews);
    }, [doctorInfo])

    if (_.isNil(reviews)) {
        return <SpinnerMedium />
    }
    console.log("[DoctorRevieList] reviews", reviews);

    if (_.isEmpty(reviews)) {
        return (
            <div>
                <div className="text-center text-gray-500 py-10">
                    작성된 리뷰가 없습니다
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col space-y-2 mt-2">
            {reviews.map((review, idx) => (
                <div>
                    <ReviewCard key={idx} review={review} />
                    <div className="border-t border-gray-200 mt-2"></div>
                </div>
            ))}
        </div>
    )
}

export default DoctorReviewList;