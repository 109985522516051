import React from "react"
import { useLocation } from "react-router-dom";
import { HomeIcon, UserCircleIcon, ChatBubbleBottomCenterTextIcon, HeartIcon, MagnifyingGlassIcon ,CalendarDaysIcon, BookOpenIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"
import { classNames } from "components/uiUtils";

const Menu = ({ title, Icon, to }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onMenuClicked = () => {
        navigate(to);
    }

    const isSelected = location.pathname.startsWith(to);

    return (
        <button
            type="button"
            className="inline-flex flex-col items-center justify-center font-medium hover:bg-gray-50 dark:hover:bg-gray-800 group w-full"
            onClick={onMenuClicked}
        >
            <span className={classNames(
                "text-sm text-gray-500 dark:text-gray-400 group-hover:text-pink-500 dark:group-hover:text-pink-400 flex-col",
                isSelected ? "text-pink-600 dark:text-pink-500" : ""
            )}>
                <div className="flex justify-center">
                    <Icon className="size-5"></Icon>
                </div>
                <div className="text-nowrap text-xs">
                    {title}
                </div>
            </span>
        </button>
    )
}

export default () => {
    return (
        <div className="sticky bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
            <div className="flex h-full max-w-lg mx-auto">
                <Menu title="홈" Icon={HomeIcon} to={"/home"}></Menu>
                <Menu title="탐색" Icon={BookOpenIcon} to={"/contents"}></Menu>
                <Menu title="찜" Icon={HeartIcon} to={"/bookmark"}></Menu>
                <Menu title="예약" Icon={CalendarDaysIcon} to={"/reservation"}></Menu>
                <Menu title="마이페이지" Icon={UserCircleIcon} to={"/my-page"}></Menu>
            </div>
        </div>
    )
}