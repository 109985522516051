import React from "react"
import Badge from "./BadgeWithRemove"

export default ({ items = [], colors = [], onRemoveClicked = (item: string) => { }, isRemovable = true }) => {
    return (
        <div className="flex overflow-scroll">
            {items.map((item, i) => (
                <div className="me-2" key={i}>
                    <Badge
                        label={item}
                        key={i}
                        onRemoveClicked={() => { onRemoveClicked(item) }}
                        color={colors[i] ?? ""}
                        isRemovable={isRemovable}
                    />
                </div>
            ))}
        </div>
    )
}