import TopNav from "common/components/TobNav";
import React from "react";

const ReservationPage = () => {
    return (
        <div>
            <TopNav pages={[{ name: "예약내역" }]} disablePlus={true} />
            <div className="text-center text-gray-500 py-10">
                예약 내역이 없습니다
            </div>
        </div>
    )
}

export default ReservationPage;
