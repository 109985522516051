import { StarIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import BasicArticle from "components/Article/Basic";
import Badges from "components/Badges";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DoctorProfile } from "../DoctorDetail";
import { EventCard } from "../ClinicDetail/ClinicEventList";
import TopNav from "common/components/TobNav";

export const ReviewPhotoCard = ({ url, idx, type }) => {
    return (
        <div className="w-40 h-40 relative rounded-md overflow-hidden cursor-pointer" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(url, 'newWindow', 'width=1280, height=720, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
        }}>
            <img
                key={idx}
                src={url}
                className="w-full h-full object-cover shadow-md"
                alt={`procedure_img_${idx}`}
            />
            <span className="absolute bg-gray-600 left-0 bottom-0 w-5 h-5 text-white font-semibold text-xs text-center content-center rounded-tr-md">
                {type}
            </span>
        </div>
    )
}

const ReviewDetail = () => {
    const { reviewId } = useParams();
    const [reviewInfo, setReviewInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (reviewId === "pv") {
            console.log("[ReviewDetil] reviewId is empty it's maybe preview page");
            console.log("[ReviewDetil] try get from localstorage");
            const tempReviewInfoStr = localStorage.getItem("tempReviewInfo");
            if (_.isNil(tempReviewInfoStr)) {
                console.log("[ReviewDetil] tempReviewInfo is empty");
                return;
            }
            const tempReviewInfo = JSON.parse(tempReviewInfoStr);
            console.log("[ReviewDetil] tempReviewInfo", tempReviewInfo);
            setReviewInfo(tempReviewInfo);
        }
        fetchReviewInfo();
    }, [reviewId]);

    const fetchReviewInfo = async () => {
        if (_.isNil(reviewId)) {
            return;
        }
        try {
            const ret = await axios.get(`https://rest.miview.shop/review/${reviewId}`);
            console.log("[fetchReviewInfo] ret", ret.data);
            setReviewInfo(ret.data);
        } catch (e) {
            console.error("[fetchReviewInfo] error", e);
        }
    }

    console.log("[ReviewDetil] review", reviewInfo);

    if (_.isNil(reviewInfo)) {
        return <SpinnerMedium />;
    }

    return (
        <div>
            <TopNav
                pages={[
                    {
                        name: "탐색",
                        path: "/contents"
                    },
                    {
                        name: "리뷰",
                        path: "/contents?tabId=reviews"
                    },
                    {
                        name: reviewInfo.title
                    }
                ]}
                disablePlus={true}
            />
            <div className="flex flex-col">
                {/* <div>
                <img src={reviewInfo.cardImage} alt={reviewInfo.title} />
            </div> */}
                <div className="border-t border-gray-200"></div>
                <div>
                    <div className="p-5">
                        <span className="font-bold text-lg">
                            {reviewInfo.title}
                        </span>
                        <div className="flex items-center mt-2">
                            <StarIcon className="w-4 h-4 text-yellow-400" />
                            <span className="text-gray-500 text-xs">
                                {'5.0'}
                            </span>
                            {/* <span className="ms-[2px] text-gray-600 text-xs">
                            {`(리뷰 ${10}개)`}
                        </span> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-200">
                        <div className="p-5">
                            <div className="font-semibold">
                                시술명
                            </div>
                            <div className="mt-3">
                                <Badges
                                    items={reviewInfo.treatments.map((item) => item.name)}
                                    isRemovable={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-200"></div>
                    <div className="p-5">
                        <div className="font-semibold">
                            시술사진
                        </div>
                        <div className="mt-3 overflow-auto">
                            <div className="flex space-x-3 pb-3 min-w-max">
                                {reviewInfo.beforePhotos.map((url, idx) => (
                                    <ReviewPhotoCard
                                        key={idx}
                                        url={url}
                                        idx={idx}
                                        type="전"
                                    />
                                ))}
                                {reviewInfo.afterPhotos.map((url, idx) => (
                                    <ReviewPhotoCard
                                        key={idx}
                                        url={url}
                                        idx={idx}
                                        type="후"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-gray-200"></div>
                <div className="p-5">
                    <div className="font-semibold">
                        리뷰내용
                    </div>
                    <div className="mt-3">
                        <BasicArticle>
                            <div className="text-sm leading-normal">
                                {reviewInfo.content}
                            </div>
                        </BasicArticle>
                    </div>
                </div>
                {/* <div className="border-t border-gray-200"></div>
            <div>
                <div className="p-5">
                    <div className="font-semibold">
                        상세정보
                    </div>
                </div>
                {reviewInfo.detailImages.map((url, idx) => {
                    return (
                        <div>
                            <img src={url} alt={`detail_img_${idx}`} />
                        </div>
                    )
                })}
            </div> */}
                <div>
                    <div className="border-t border-gray-200"></div>
                    <div className="p-5 cursor-pointer" onClick={() => {
                        navigate(`/contents/event/${reviewInfo.event.id}`);
                    }}>
                        <div className="font-semibold">
                            이벤트정보
                        </div>
                        <div className="mt-3">
                            <EventCard event={reviewInfo.event} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-200"></div>
                    <div className="p-5 cursor-pointer" onClick={() => {
                        navigate(`/contents/clinic/${reviewInfo.clinic.id}`);
                    }}>
                        <div className="font-semibold">
                            의원 정보
                        </div>
                        <div className="flex mt-3 items-center">
                            <img
                                hidden={_.isEmpty(reviewInfo.clinic.profileImage)}
                                src={reviewInfo.clinic.profileImage}
                                alt="profileImage"
                                className="h-24 w-24 p-2 object-center rounded-full"
                            />
                            <div className="p-2">
                                <div className="text-lg font-bold self-center">
                                    {reviewInfo.clinic.name}
                                </div>
                                <div className="text-sm text-gray-500 self-center">
                                    {"서울 ・ 강남역"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-200"></div>
                    <div className="p-5 cursor-pointer">
                        <div className="font-semibold">
                            의사 정보
                        </div>
                        {reviewInfo.doctors.map((doctor, idx) => (
                            <div onClick={() => {
                                navigate(`/contents/doctor/${doctor.id}`);
                            }}>
                                <DoctorProfile
                                    key={idx}
                                    doctor={doctor}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewDetail;
