import React from "react";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import EventPage from "pages/Event";
import ClinicList from "pages/Clinic";
import TopNav from "common/components/TobNav";

const BookmarkPage = () => {
    return (
        <div>
            <TopNav pages={[{ name: "찜 목록" }]} disablePlus={true} />
            <Tabs tabsClassnames='sticky top-0 bg-white' isEvenFullWidth={true}>
                <Tab title='이벤트' path="events">
                    <EventPage  filterParams={{ limit: 1 }} />
                </Tab>
                <Tab title='의원' path="clinics">
                    <ClinicList filterParams={{ limit: 2 }} />
                </Tab>
            </Tabs>
        </div>
    )
}

export default BookmarkPage;
