import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicBottomNav from 'containers/BottomNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';

import "./App.scss";
import AppLayout from 'common/layouts/AppLayout';
import { infoService } from 'common/services/info';
import SpinnerMedium from 'components/Spinner/Medium';

function App() {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        try {
            await infoService.init();
            setIsReady(true);
        } catch (e) {
            console.error("[init] error", e);
        }
    }

    if (!isReady) {
        return <SpinnerMedium></SpinnerMedium>
    }
    return (
        <AppLayout>
            <div>
                <div className="min-h-screen">
                    <PublicSiteRoute></PublicSiteRoute>
                </div>
                <PublicBottomNav></PublicBottomNav>
            </div>
        </AppLayout>
    );
}

export default App;