import React from "react";

const AppLayout = ({ children }) => {
    return (
        <div className="main-container">
            <div className="main-content">
                <div className="bg-gradient-to-bl from-pink-300 to-transparent bg-pink-100">
                    {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                    <div className="relative mx-auto max-w-sm bg-white shadow-2xl min-h-screen">{/* Content goes here */}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AppLayout;