import React, { useEffect, useState } from "react";
import { data } from "./example";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "containers/Search";
import numeral from "numeral";
import { useSearchParams } from "react-router-dom";
import { ReviewCard } from "common/pages/ClinicDetail/ClinicReviewList";
import axios from "axios";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";

// export const ReviewCard = ({ rating, treatmentName, price, text, clinicName }) => (
//     <Card>
//         <div className="group rounded-md overflow-hidden">
//             <div className="flex items-center">
//                 <div className="p-2 flex flex-col text-xs space-y-1.5 w-full">
//                     <div className="flex items-center">
//                         <StarIcon className="w-4 h-4 text-yellow-400" />
//                         <span className="text-gray-500 text-xs">
//                             {rating}
//                         </span>
//                     </div>
//                     <div>
//                         <h1 className="font-normal font-sans">
//                             {treatmentName}
//                         </h1>
//                     </div>
//                     <div className="flex items-center">
//                         <span className="text-pink-700 text-xs">
//                             {`¥${numeral(price).format("0,0")}`}
//                         </span>
//                     </div>
//                     <div className="flex py-2">
//                         <span className="text-sm">
//                             {text}
//                         </span>
//                     </div>
//                     <div className="flex justify-end">
//                         <h1 className="font-normal font-sans text-gray-600">
//                             {clinicName}
//                         </h1>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </Card>
// );

const ReviewPage = ({ filterParams = { offset: 0, limit: 10000 } as any }) => {
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        if(_.isNil(filterParams)) {
            return;
        }
        fetchReviews();
    }, [filterParams])

    const fetchReviews = async () => {
        try {
            const ret = await axios.get("https://rest.miview.shop/review", { params: filterParams });
            console.log("[Review Page] reviews ret", ret.data);
            setReviews(ret.data.data);
        } catch (e) {
            console.error("[fetchReviews] error", e);
        }
    }

    if (_.isNil(reviews)) {
        return (
            <SpinnerMedium />
        )
    }

    return (
        <div>
            <div className="px-2 pt-2 text-sm">
                {`${reviews?.length}건의 리뷰가 검색되었습니다`}
            </div>
            {reviews?.map((item, index) => (
                <Card>
                    <ReviewCard
                        review={item}
                    />
                </Card>
            ))}
        </div>
    )
}

export default ReviewPage;