import { StarIcon } from "@heroicons/react/20/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Badges from "components/Badges";
import Card from "components/Card";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const DoctorCard = ({ doctor }) => {
    const navigate = useNavigate();

    return (
        <div className="cursor-pointer" onClick={() => {
            navigate(`/contents/doctor/${doctor.id}`);
        }}>
            <div className="group rounded-md overflow-hidden">
                <div className="flex items-center">
                    <div>
                        {!_.isEmpty(doctor.profileImage) ?
                            <div className="size-24">
                                <img
                                    src={doctor.profileImage}
                                    alt="profileImage"
                                    className="p-2 object-center group-hover:opacity-75 rounded-full"
                                />

                            </div>
                            :
                            <div className="flex justify-center text-pink-300">
                                <UserCircleIcon className="size-24" />
                            </div>
                        }
                    </div>
                    <div className="p-2 flex flex-col space-y-2 overflow-hidden">
                        <div>
                            <h1 className="text-lg font-bold">
                                {`${doctor.name} 원장`}
                            </h1>
                        </div>
                        <div className="flex items-center">
                            <StarIcon className="w-4 h-4 text-yellow-400" />
                            <span className="text-gray-500 text-xs">
                                {'5.0'}
                            </span>
                            {/* <span className="ms-[2px] text-gray-600 text-xs">
                                {`口コミ${10}件症例${50}件`}
                            </span> */}
                        </div>
                        {/* <div className="flex items-center">
                            <span className="text-pink-700 text-xs">
                                {`¥${numeral(price).format("0,0")}`}
                            </span>
                            <span className="text-gray-600 text-[10px] ps-1">
                                {` | 通常価格：¥${numeral(originPrice).format("0,0")}`}
                            </span>
                        </div> */}
                        <div className="overflow-auto">
                            <Badges
                                items={doctor.specialties.map((sp) => sp.name)}
                                isRemovable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClinicDoctorList = ({ clinicInfo }) => {
    const [doctors, setDoctors] = useState(null);

    useEffect(() => {
        if (_.isNil(clinicInfo.doctors)) {
            console.log("[ClinicDoctorList] clinicInfo.doctors is empty");
            return;
        }
        setDoctors(clinicInfo.doctors);
    }, [clinicInfo])

    if (_.isNil(doctors)) {
        return <SpinnerMedium />
    }
    console.log("[ClinicDoctorList] doctors", doctors);

    if (_.isEmpty(doctors)) {
        return (
            <div>
                <div className="text-center text-gray-500 py-10">
                    등록된 의료진이 없습니다
                </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col space-y-2 mt-2">
            {doctors.map((doctor, idx) => (
                <DoctorCard key={idx} doctor={doctor} />
            ))}
        </div>
    )
}

export default ClinicDoctorList;
