import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Breadcrumbs from "common/components/Breadcrumbs";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "assets/logo/logo-no-background.png";
import _ from "lodash";
import { useModal } from "components/Modal/Simple";
import SearchPage from "common/pages/SearchPage";

const TopNav = ({ pages = [], disablePlus = false, onPlusClick = () => { }, onBreadcrumbsClick = (page) => { } }) => {
    const { Modal: SearchModal, openModalSync: openSearchModal, closeModal: closeModal } = useModal({
        styles: {
            content: {
                marginTop: "20px",
                overflowX: "hidden",
                maxHeight: "calc(100vh - 80px)",
            }
        }
    });
    const navigate = useNavigate();

    const onBreadcrumbsClicked = (page) => {
        if (_.isNil(page.path)) {
            onBreadcrumbsClick(page);
            return;
        }
        navigate(page.path);
    }

    return (
        <div className="sticky top-0 left-0 border-b border-slate-900/10 bg-white z-50">
            <div className="flex justify-between items-center space-x-2 px-2 py-4">
                {_.isEmpty(pages) ?
                    <img src={logo} className="w-[120px]" />
                    :
                    <div className="overflow-auto">
                        <Breadcrumbs
                            pages={pages}
                            disablePlus={disablePlus}
                            onBreadcrumbsClicked={onBreadcrumbsClicked}
                            onPlusClicked={onPlusClick}
                        />
                    </div>
                }
                <div className="cursor-pointer" onClick={openSearchModal}>
                    <MagnifyingGlassIcon className="size-5 text-slate-900/50" />
                </div>
            </div>
            <SearchModal>
                <SearchPage onSelect={closeModal}/>
            </SearchModal>
        </div>
    )
}

export default TopNav;
