import React, { useEffect, useState } from "react";
import { data } from "./example";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "containers/Search";
import numeral from "numeral";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { EventCard } from "common/pages/ClinicDetail/ClinicEventList";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";

// export const EventCard = ({ mainImg, clinicName, name, rating, nReview, nReceipt, price, originPrice }) => (
//     <Card>
//         <div className="group rounded-md overflow-hidden">
//             <div className="flex items-center">
//                 <img
//                     src={mainImg}
//                     alt="clinic"
//                     className="h-24 w-24 p-2 object-center group-hover:opacity-75"
//                 />
//                 <div className="p-2 flex flex-col text-xs space-y-2">
//                     <div>
//                         <h1 className="font-normal font-sans text-gray-600">
//                             {clinicName}
//                         </h1>
//                         <h1 className="font-normal font-sans">
//                             {name}
//                         </h1>
//                     </div>
//                     <div className="flex items-center">
//                         <StarIcon className="w-4 h-4 text-yellow-400" />
//                         <span className="text-gray-500 text-xs">
//                             {rating}
//                         </span>
//                         {/* <span className="ms-[2px] text-gray-600 text-xs">
//                             {`口コミ${nReview}件症例${nReceipt}件`}
//                         </span> */}
//                     </div>
//                     {/* <div className="flex items-center">
//                         <span className="text-pink-700 text-xs">
//                             {`¥${numeral(price).format("0,0")}`}
//                         </span>
//                         <span className="text-gray-600 text-[10px] ps-1">
//                             {` | 通常価格：¥${numeral(originPrice).format("0,0")}`}
//                         </span>
//                     </div> */}
//                 </div>
//             </div>

//         </div>
//     </Card>
// )

const EventPage = ({ filterParams = { offset: 0, limit: 10000 } as any }) => {
    const [events, setEvents] = useState(null);

    useEffect(() => {
        if (_.isNil(filterParams)) {
            return;
        }
        fetchEvents();
    }, [filterParams])

    const fetchEvents = async () => {
        const response = await axios.get("https://rest.miview.shop/event", { params: filterParams });
        console.log("[fetchEvents] response", response);
        setEvents(response.data.data);
    }

    if (_.isNil(events)) {
        return (
            <SpinnerMedium />
        )
    }

    return (
        <div>
            <div className="px-2 pt-2 text-sm">
                {`${events?.length}건의 이벤트가 검색되었습니다`}
            </div>
            {events?.map((item, index) => (
                <Card>
                    <EventCard
                        event={item}
                    />
                </Card>
            ))}
        </div>
    )
}

export default EventPage;