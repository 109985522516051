import React, { useEffect, useState } from "react";
import { data } from "./example";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "containers/Search";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import { infoService } from "common/services/info";
import SpinnerMedium from "components/Spinner/Medium";
import axios from "axios";
import Badges from "components/Badges";

export const ClinicCard = ({ clinic }) => {
    const navigate = useNavigate();

    return (
        <Card>
            <div className="group overflow-hidden cursor-pointer" onClick={() => { navigate(`/contents/clinic/${clinic.id}`) }}>
                <img
                    hidden={_.isNil(clinic.bannerImage)}
                    src={clinic.bannerImage}
                    alt="clinic"
                    className="h-36 w-full object-cover object-center group-hover:opacity-75"
                />
                <div className="p-2 flex flex-col">
                    <div className="flex items-center space-x-2">
                        <div>
                            <h1 className="font-normal font-sans">
                                {clinic.name}
                            </h1>
                        </div>
                        <div className="flex items-center ">
                            <StarIcon className="w-4 h-4 text-yellow-400" />
                            <span className="text-gray-500 text-sm">
                                {"5.0"}
                            </span>
                        </div>
                        {/* <span className="ms-[2px] text-gray-600 text-sm">
                        {`口コミ${nReview}件症例${nReceipt}件`}
                    </span>
                    <span className="ms-[2px] text-gray-600 text-sm">
                        {`|`}
                    </span>
                    <span className="ms-[2px] text-gray-600 text-sm">
                        {`イベント${nEvent}件`}
                    </span> */}
                    </div>
                    <div className="flex">
                        <Badges items={clinic.specialties.map((sp) => sp.name)} isRemovable={false} />
                    </div>
                </div>
            </div>
        </Card>
    )
}

const ClinicList = ({ filterParams = { offset: 0, limit: 10000 } as any }) => {

    const [clinics, setClinics] = useState(null);

    useEffect(() => {
        if(_.isNil(filterParams)) {
            return;
        }
        fetchClinics();
    }, [filterParams])

    const fetchClinics = async () => {
        console.log("[ClinicList] fetchClinics filterParams", filterParams);
        try {
            const ret = await axios.get("https://rest.miview.shop/clinic", { params: {
                ...filterParams,
                offset: 0,
                limit: 100000,
            } });
            setClinics(ret.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    if (_.isNil(clinics)) {
        return (
            <SpinnerMedium />
        )
    }

    return (
        <div>
            <div className="px-2 pt-2 text-sm">
                {`${clinics?.length}개의 의원이 검색되었습니다`}
            </div>
            {clinics.map((item, index) => (
                <ClinicCard
                    clinic={item}
                />
            ))}
        </div>
    )
}

export default ClinicList;