import Button from "components/Form/Button";
import Input from "components/Form/Input";
import React, { useEffect, useState } from "react";
import { useDaumPostcodePopup } from 'react-daum-postcode';
import _ from "lodash";

const DAUM_ADDRESS_CODE_SEARCH_URL = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

export type KoreanAddressType = {
    zoneCode: string;
    roadAddress: string;
    detailAddress: string;
}

export const formatKoreanAddress = (addressObj: KoreanAddressType) => {
    if (_.isEmpty(addressObj)) {
        return "";
    }
    // return `(${addressObj?.zoneCode}) ${addressObj?.roadAddress} ${addressObj?.detailAddress}`;
    return `${addressObj?.roadAddress} ${addressObj?.detailAddress}`;

}

const EMPTY_ADDRESS = {
    zoneCode: "",
    roadAddress: "",
    detailAddress: "",
}

const KoreanAddressInput = ({ initialAddress = EMPTY_ADDRESS, onChange = (addressObj: KoreanAddressType) => { } }) => {
    const open = useDaumPostcodePopup(DAUM_ADDRESS_CODE_SEARCH_URL);
    const [addressObj, setAddressObj] = useState(EMPTY_ADDRESS);

    useEffect(() => {
        if(_.isEmpty(initialAddress)) {
            return;
        }
        setAddressObj(initialAddress);
    }, [initialAddress])

    const onButtonClicked = async () => {
        open({
            onComplete: (data) => {
                console.log("onComplete", data);
                const { zonecode, roadAddress, buildingName } = data;
                setAddressObj({
                    zoneCode: zonecode,
                    roadAddress,
                    detailAddress: buildingName ?? "",
                });
            },
            onSearch: (query) => {
                console.log("onSearch", query);
            },
        });
    }

    useEffect(() => {
        onChange(addressObj);
    }, [addressObj])


    return (
        <div>
            <div className="flex space-x-2">
                <div>
                    <Button onButtonClicked={onButtonClicked}>
                        주소검색
                    </Button>
                </div>
                <div>
                    <Button onButtonClicked={()=>{setAddressObj(EMPTY_ADDRESS)}}>
                        주소삭제
                    </Button>
                </div>
            </div>
            <div className="flex space-x-2">
                <div className="w-24">
                    <Input
                        upperLabel="우편번호"
                        value={addressObj.zoneCode}
                        disabled={true}
                    />
                </div>
                <div className="w-full">
                    <Input
                        upperLabel="기본주소"
                        value={addressObj.roadAddress}
                        disabled={true}
                    />
                </div>
            </div>
            <div>
                <Input
                    upperLabel="상세주소"
                    value={addressObj.detailAddress}
                    onChange={(value) => setAddressObj({ ...addressObj, detailAddress: value })}
                    disabled={_.isEmpty(addressObj.zoneCode) || _.isEmpty(addressObj.roadAddress)}
                />
            </div>
        </div>
    )
}

export default KoreanAddressInput;
