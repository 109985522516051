import BasicArticle from "components/Article/Basic";
import Badges from "components/Badges";
import React, { useEffect, useRef, useState } from "react";
import { formatKoreanAddress } from "common/forms/KoreanAddressInput";
import { toast } from "react-toastify";
import { Wrapper as GoogleMapWrapper } from "@googlemaps/react-wrapper";
import GoogleMapComponent from "components/Map/Google";
import _ from "lodash";

export const GOOGLE_MAP_API_KEY = "AIzaSyDaTlDWhBvw3NJYMOYJoFxPGiafN9lNlQ8";

const ClinicInfo = ({ clinicInfo }) => {

    const koreanAddress = formatKoreanAddress(clinicInfo.address);

    return (
        <div>
            <div className="p-5">
                <div className="font-semibold">
                    병원소개
                </div>
                <div className="mt-3">
                    <BasicArticle>
                        <div className="text-sm leading-normal">
                            {clinicInfo.description}
                        </div>
                    </BasicArticle>
                </div>
            </div>
            <div className="border-t border-gray-200"></div>
            <div className="p-5">
                <div className="font-semibold">
                    진료과목
                </div>
                <div className="mt-3">
                    <Badges
                        items={clinicInfo.specialties.map((sp) => sp.name)}
                        isRemovable={false}
                    />
                </div>
            </div>
            <div className="border-t border-gray-200"></div>
            <div className="p-5">
                <div className="font-semibold">
                    주소
                </div>
                <div className="mt-3 text-sm leading-normal flex space-x-2">
                    <span className="">
                        {koreanAddress}
                    </span>
                    <span className="cursor-pointer text-blue-700 font-semibold"
                        hidden={_.isEmpty(_.trim(koreanAddress))}
                        onClick={() => {
                            navigator.clipboard.writeText(koreanAddress);
                            toast.success("주소가 복사되었습니다.");
                        }}
                    >
                        복사
                    </span>
                </div>
                <div className="mt-3">
                    <GoogleMapWrapper apiKey={GOOGLE_MAP_API_KEY}>
                        <GoogleMapComponent
                            address={koreanAddress}
                            title={clinicInfo.name}
                            width="100%"
                            height="200px"
                        />
                    </GoogleMapWrapper>
                </div>
            </div>
        </div>
    )
}

export default ClinicInfo;
