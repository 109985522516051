import { StarIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import BasicArticle from "components/Article/Basic";
import SpinnerMedium from "components/Spinner/Medium";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Wrapper as GoogleMapWrapper } from "@googlemaps/react-wrapper";
import GoogleMapComponent from "components/Map/Google";
import { GOOGLE_MAP_API_KEY } from "../ClinicDetail/ClinicInfo";
import { formatKoreanAddress } from "common/forms/KoreanAddressInput";
import TopNav from "common/components/TobNav";

const EventDetail = ({ }) => {
    const { eventId } = useParams();
    const [eventInfo, setEventInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (eventId === "pv") {
            console.log("[EventDetail] eventId is empty it's maybe preview page");
            console.log("[EventDetail] try get from localstorage");
            const tempEventInfoStr = localStorage.getItem("tempEventInfo");
            if (_.isNil(tempEventInfoStr)) {
                console.log("[EventDetail] tempEventInfoStr is empty");
                return;
            }
            const tempEventInfo = JSON.parse(tempEventInfoStr);
            console.log("[EventDetail] tempEventInfo", tempEventInfo);
            setEventInfo(tempEventInfo);
        }
        fetchEventInfo();
    }, [eventId]);

    const fetchEventInfo = async () => {
        if (_.isNil(eventId)) {
            return;
        }
        try {
            const ret = await axios.get(`https://rest.miview.shop/event/${eventId}`);
            console.log("[fetchEventrInfo] ret", ret.data);
            setEventInfo(ret.data);
        } catch (e) {
            console.error("[fetchEventrInfo] error", e);
        }
    }

    console.log("[EventDetail] eventInfo", eventInfo);

    if (_.isNil(eventInfo)) {
        return <SpinnerMedium />;
    }

    return (
        <div>
            <TopNav
                pages={[
                    {
                        name: "탐색",
                        path: "/contents"
                    },
                    {
                        name: "이벤트",
                        path: "/contents?tabId=events"
                    },
                    {
                        name: eventInfo.title
                    }
                ]}
                disablePlus={true}
            />
            <div className="flex flex-col">
                <div>
                    <img src={eventInfo.cardImage} alt={eventInfo.title} />
                </div>
                <div className="border-t border-gray-200"></div>
                <div>
                    <div className="p-5">
                        <span className="font-bold text-lg">
                            {eventInfo.title}
                        </span>
                        <div className="flex items-center mt-2">
                            <StarIcon className="w-4 h-4 text-yellow-400" />
                            <span className="text-gray-500 text-xs">
                                {'5.0'}
                            </span>
                            <span className="ms-[2px] text-gray-600 text-xs">
                                {`(리뷰 ${10}개)`}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="border-t border-gray-200"></div>
                <div className="p-5">
                    <div className="font-semibold">
                        이벤트 설명
                    </div>
                    <div className="mt-3">
                        <BasicArticle>
                            <div className="text-sm leading-normal">
                                {eventInfo.content}
                            </div>
                        </BasicArticle>
                    </div>
                </div>
                <div className="border-t border-gray-200"></div>
                <div>
                    <div className="p-5">
                        <div className="font-semibold">
                            상세정보
                        </div>
                    </div>
                    {eventInfo.detailImages.map((url, idx) => {
                        return (
                            <div>
                                <img src={url} alt={`detail_img_${idx}`} />
                            </div>
                        )
                    })}
                </div>
                <div className="border-t border-gray-200"></div>
                <div className="p-5 cursor-pointer" onClick={() => {
                    navigate(`/contents/clinic/${eventInfo.clinic.id}`);
                }}>
                    <div className="font-semibold">
                        의원 정보
                    </div>
                    <div className="flex mt-3 items-center">
                        <img
                            hidden={_.isEmpty(eventInfo.clinic.profileImage)}
                            src={eventInfo.clinic.profileImage}
                            alt="profileImage"
                            className="h-24 w-24 p-2 object-center rounded-full"
                        />
                        <div className="p-2">
                            <div className="text-lg font-bold self-center">
                                {eventInfo.clinic.name}
                            </div>
                            <div className="text-sm text-gray-500 self-center">
                                {"서울 ・ 강남역"}
                            </div>
                        </div>
                    </div>
                    <GoogleMapWrapper apiKey={GOOGLE_MAP_API_KEY}>
                        <GoogleMapComponent
                            address={formatKoreanAddress(eventInfo.clinic.address)}
                            title={eventInfo.clinic.name}
                            width="100%"
                            height="200px"
                        />
                    </GoogleMapWrapper>
                </div>
            </div>
        </div>

    )
}

export default EventDetail;
