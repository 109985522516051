import React from "react";

const EmptyForm = ({ children }) => {
    return (
        <div className="ring-1 ring-inset ring-gray-300 rounded-md">
            {children}
        </div>
    )
}

export default EmptyForm;
