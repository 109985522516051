import React from 'react'
import { ChevronRightIcon, HomeIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useModal } from 'components/Modal/Simple';
import { classNames } from 'components/uiUtils';
import _ from 'lodash';


const examplePages = [
    {
        name: 'Projects',
        path: null,
        data: null
    },
    {
        name: 'Project Nero',
        path: null,
        data: null
    },
]

const Breadcrumbs = ({ pages = examplePages, onBreadcrumbsClicked = (page) => { }, onPlusClicked = () => { }, disablePlus = false }) => {
    console.log("[Breadcrumbs] pages", pages);
    return (
        <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
                {/* <li>
                    <div>
                        <a className="text-gray-400 hover:text-gray-500" onClick={() => { navigate("/") }}>
                            <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li> */}
                {pages.map((page, index) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <div hidden={index === 0}>
                                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                            </div>
                            <a
                                onClick={() => { onBreadcrumbsClicked(page) }}
                                className={
                                    classNames(
                                        "ml-1 hover:text-gray-700 text-nowrap cursor-pointer",
                                        index===0 ?"text-gray-900 font-bold": "text-gray-500 text-sm"
                                    )}
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
                <li hidden={disablePlus} >
                    <div className="flex items-center">
                        <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                        <a className="ml-1 text-gray-400 hover:text-gray-500" onClick={() => { onPlusClicked() }}>
                            <PlusCircleIcon aria-hidden="true" className="size-4 flex-shrink-0" />
                        </a>
                    </div>
                </li>
            </ol>
        </nav>
    )
}

export default Breadcrumbs;