import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import numeral from 'numeral';
import { Tooltip } from 'react-tooltip';
import Textarea from 'components/Form/Textarea';
import BasicArticle from 'components/Article/Basic';
import { randomStr } from 'components/uiUtils';

export const formatDate = (date: string) => {
    if (_.isNil(date)) return "";
    const dateStr = moment(date).format("'YY-MM-DD");
    if (dateStr === "Invalid date") return "";
    return dateStr;
}

export const formatDateTime = (date: string) => {
    if (_.isNil(date)) return "";
    const dateStr = moment(date).format("'YY-MM-DD HH:mm");
    if (dateStr === "Invalid date") return "";
    return dateStr;
}

export const formatDollar = (value: number) => {
    if (_.isNil(value)) return "";
    return numeral(value).format("$ 0,0");
}

export const formatBoolean = (value: boolean) => {
    return value ? "○" : "✕";
}

export const truncate = (text: string, length = 12) => {
    if (_.isNil(text)) {
        return "";
    }
    if (text.length > length) {
        let rnd = randomStr(10);
        const anchor = `truncated-contents_${rnd}`;
        return (
            <div>
                <div className={anchor}>
                    {text.slice(0, length) + "..."}
                </div>
                <Tooltip
                    className="z-50"
                    anchorSelect={`.${anchor}`}
                    place="right"
                    variant="light"
                    border="1px solid #cccccc"
                >
                    <BasicArticle>
                        {text}
                    </BasicArticle>
                </Tooltip>
            </div>
        )
    } else {
        return text;
    }
}

export const renderHalfWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[50vw]">
            {value}
        </div>
    )
}

export const renderMediumWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[33vw]">
            {value}
        </div>
    )
}

export const renderQuarterWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[25vw]">
            {value}
        </div>
    )
}


export const renderSmallWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[10vw]">
            {value}
        </div>
    )
}