import axios from 'axios';

class InfoService {
    specialties = null;
    parts = null;
    concerns = null;
    treatments = null;
    clinics = null;

    init = async () => {
        console.log("[init] called");
        await this.fetchSpecialties();
        await this.fetchParts();
        await this.fetchConcerns();
        await this.fetchTreatments();
        await this.fetchClinics();
    }

    fetchSpecialties = async () => {
        try {
            const ret = await axios.get(`https://rest.miview.shop/specialty`);
            console.log("[fetchSpecialties] ret", ret);
            this.specialties = ret.data?.data;
            return this.specialties;
        } catch (e) {
            console.error("[fetchSpecialties] error", e);
            return null;
        }
    }

    fetchParts = async () => {
        try {
            const ret = await axios.get(`https://rest.miview.shop/part`);
            console.log("[fetchParts] ret", ret);
            this.parts = ret.data?.data;
            return this.parts;
        } catch (e) {
            console.error("[fetchParts] error", e);
            return null;
        }
    }

    fetchConcerns = async () => {
        try {
            const ret = await axios.get(`https://rest.miview.shop/concern`);
            console.log("[fetchConcerns] ret", ret);
            this.concerns = ret.data?.data;
            return this.concerns;
        } catch (e) {
            console.error("[fetchConcerns] error", e);
            return null;
        }
    }

    fetchTreatments = async () => {
        try {
            const params = {
                offset: 0,
                limit: 10000
            }
            const ret = await axios.get(`https://rest.miview.shop/treatment`, { params });
            console.log("[fetchTreatments] ret", ret);
            this.treatments = ret.data?.data;
            return this.treatments;
        } catch (e) {
            console.error("[fetchTreatments] error", e);
            return null;
        }
    }

    fetchClinics = async () => {
        try {
            const params = {
                offset: 0,
                limit: 10000
            }
            const ret = await axios.get(`https://rest.miview.shop/clinic`, { params });
            console.log("[fetchClinics] ret", ret);
            this.clinics = ret.data?.data;
            return this.clinics;
        } catch (e) {
            console.error("[fetchClinics] error", e);
            return null;
        }
    }

    getSpecialties = () => {
        return this.specialties;
    }

    getSpecialtyById = (id) => {
        return this.specialties?.find((item) => Number(item.id) === Number(id));
    }

    getParts = () => {
        return this.parts;
    }

    getPartById = (id) => {
        return this.parts?.find((item) => Number(item.id) === Number(id));
    }

    getConcerns = () => {
        return this.concerns;
    }

    getConcernById = (id) => {
        return this.concerns?.find((item) => Number(item.id) === Number(id));
    }

    getConcernsByPartId = (partId) => {
        return this.concerns?.filter((concern) => concern.parts.map(part => part.id).includes(Number(partId)));
    }

    getTreatments = () => {
        return this.treatments;
    }

    getTreatmentById = (id) => {
        return this.treatments?.find((item) => Number(item.id) === Number(id));
    }

    getTreatmentsByConcernId = (concernId) => {
        return this.treatments?.filter((treatment) => treatment.concerns.map(concern => concern.id).includes(Number(concernId)));
    }

    getTreatmentsBySpecialtyId = (specialtyId) => {
        return this.treatments?.filter((treatment) => treatment.specialties.map(specialty => specialty.id).includes(Number(specialtyId)));
    }

    getClinics = () => {
        return this.clinics;
    }
}

export const infoService = new InfoService();
