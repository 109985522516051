import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import ClinicList from 'pages/Clinic';
import EventPage from 'pages/Event';
import React, { useEffect, useState } from 'react';
import { parts as allParts, concerns as allConcerns, treatments as allTreatments } from 'pages/Home/example';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import ReviewPage from 'pages/Review';
import _ from 'lodash';
import { useModal } from 'components/Modal/Simple';
import TopNav from 'common/components/TobNav';
import { infoService } from 'common/services/info';
import Button from 'components/Form/Button';
import PageTitle from 'components/Title/Page';
import DoctorPage from 'pages/Doctor';

const ContentsPage = () => {
    const { Modal: FilterSelectModal, openModalSync: openFilterSelectModalSync, closeModal: closeFilterSelectModal } = useModal();
    const { Modal: PartSelectModal, openModalSync: openPartSelectModalSync, closeModal: closePartSelectModal } = useModal();
    const { Modal: ConcernSelectModal, openModalSync: openConcernSelectModalSync, closeModal: closeConcernSelectModal } = useModal();
    const { Modal: TreatmentSelectModal, openModalSync: openTreatmentSelectModalSync, closeModal: closeTreatmentSelectModal } = useModal();
    const { Modal: SpecialtySelectModal, openModalSync: openSpecialtySelectModalSync, closeModal: closeSpecialtySelectModal } = useModal();

    const [searchParams, setSearchParams] = useSearchParams();
    const [filterParams, setFilterParams] = useState(null);

    useEffect(() => {
        console.log("[ContentsPage] useEffect searchParams", searchParams);
        const tempFilterParams = _.omit(Object.fromEntries(searchParams.entries()), ["tabId"]);
        console.log("[ContentsPage] tempFilterParams", tempFilterParams);
        setFilterParams(tempFilterParams);
    }, [searchParams])

    let pages = [
        {
            name: '탐색',
            path: '/contents',
            data: null
        },
    ]

    if (!_.isNil(filterParams?.specialtyId)) {
        pages = [
            ...pages,
            {
                name: `진료과목 : ${infoService.getSpecialtyById(filterParams.specialtyId).name}`,
                path: null,
                data: "SPECIALTIES"
            }
        ]

        if (!_.isNil(filterParams?.treatmentId)) {
            pages = [
                ...pages,
                {
                    name: `시술 : ${infoService.getTreatmentById(filterParams.treatmentId).name}`,
                    path: null,
                    data: "TREATMENTS"
                }
            ]
        }
    }
    if (!_.isNil(filterParams?.partId)) {
        pages = [
            ...pages,
            {
                name: `시술부위 : ${infoService.getPartById(filterParams.partId).name}`,
                path: null,
                data: "PARTS"
            },
        ]

        if (!_.isNil(filterParams?.concernId)) {
            pages = [
                ...pages,
                {
                    name: `고민 : ${infoService.getConcernById(filterParams.concernId).name}`,
                    path: null,
                    data: "CONCERNS"
                }
            ]
            if (!_.isNil(filterParams?.treatmentId)) {
                pages = [
                    ...pages,
                    {
                        name: `시술 : ${infoService.getTreatmentById(filterParams.treatmentId).name}`,
                        path: null,
                        data: "TREATMENTS"
                    }
                ]
            }
        }
    }

    const onPlusClicked = async () => {
        console.log("[Plus Clicked] filterParams", filterParams);
        if (!_.isNil(filterParams?.concernId)) {
            const ret = await openTreatmentSelectModalSync();
            console.log("[Treatment Modal Ret]", ret);
            return;
        }
        if (!_.isNil(filterParams?.partId)) {
            const ret = await openConcernSelectModalSync();
            console.log("[Concern Modal Ret]", ret);
            return;
        }
        if (!_.isNil(filterParams?.specialtyId)) {
            const ret = await openTreatmentSelectModalSync();
            console.log("[Filter Modal Ret]", ret);
            return;
        }
        // const ret = await openFilterSelectModalSync();
        const ret = await openSpecialtySelectModalSync();
        console.log("[Filter Modal Ret]", ret);
    }

    const onBreadcrumbsClicked = (page) => {
        console.log("[Breadcrumbs Clicked]", page);
        if (page.data === "PARTS") {
            openPartSelectModalSync();
        } else if (page.data === "CONCERNS") {
            openConcernSelectModalSync();
        } else if (page.data === "TREATMENTS") {
            openTreatmentSelectModalSync();
        } else if (page.data === "SPECIALTIES") {
            openSpecialtySelectModalSync();
        }
    }

    return (
        <div>
            <TopNav
                pages={pages}
                onPlusClick={onPlusClicked}
                onBreadcrumbsClick={onBreadcrumbsClicked}
                disablePlus={!_.isNil(filterParams?.treatmentId)}
            />
            <div>
                <Tabs tabsClassnames='sticky top-0 bg-white' isEvenFullWidth={true}>
                    <Tab title='의원' path="clinics">
                        <ClinicList filterParams={filterParams} />
                    </Tab>
                    <Tab title='의사' path="doctors">
                        <DoctorPage filterParams={filterParams} />
                    </Tab>
                    <Tab title='이벤트' path="events">
                        <EventPage filterParams={filterParams} />
                    </Tab>
                    <Tab title='리뷰' path="reviews">
                        <ReviewPage filterParams={filterParams} />
                    </Tab>
                </Tabs>
            </div>
            <FilterSelectModal>
                <div className="flex flex-col space-y-2">
                    <PageTitle>
                        필터 선택
                    </PageTitle>
                    <Button
                        onButtonClicked={() => {
                            closeFilterSelectModal();
                            openPartSelectModalSync()
                        }}
                    >
                        시술부위로 찾기
                    </Button>
                    {/* <Button>
                        진료과목으로 찾기
                    </Button> */}
                </div>
            </FilterSelectModal>
            <PartSelectModal>
                <div className="flex flex-col space-y-2">
                    <PageTitle>
                        시술부위 선택
                    </PageTitle>
                    {infoService.getParts().map((item, index) => (
                        <Button onButtonClicked={() => {
                            console.log("On Part Button Clicked", item);
                            closePartSelectModal();
                            // remove concernId
                            const tempSearchParams = new URLSearchParams(searchParams);
                            tempSearchParams.delete("concernId");
                            tempSearchParams.delete("treatmentId");
                            tempSearchParams.set("partId", item.id);
                            setSearchParams(tempSearchParams);
                        }}>
                            {item.name}
                        </Button>
                    ))}
                </div>
            </PartSelectModal>
            <ConcernSelectModal>
                <div className="flex flex-col space-y-2">
                    <PageTitle>
                        고민 선택
                    </PageTitle>
                    {infoService.getConcernsByPartId(filterParams?.partId).map((item, index) => (
                        <Button onButtonClicked={() => {
                            console.log("On Concern Button Clicked", item);
                            closeConcernSelectModal();
                            const tempSearchParams = new URLSearchParams(searchParams);
                            tempSearchParams.delete("treatmentId");
                            tempSearchParams.set("concernId", item.id);
                            setSearchParams(tempSearchParams);
                        }}>
                            {item.name}
                        </Button>
                    ))}
                </div>
            </ConcernSelectModal>
            <TreatmentSelectModal>
                <div className="flex flex-col space-y-2">
                    <PageTitle>
                        시술 선택
                    </PageTitle>
                    {infoService.getTreatmentsBySpecialtyId(filterParams?.specialtyId).map((item, index) => (
                        <Button
                            onButtonClicked={() => {
                                console.log("On Treatment Button Clicked", item);
                                closeTreatmentSelectModal();
                                const tempSearchParams = new URLSearchParams(searchParams);
                                tempSearchParams.set("treatmentId", item.id);
                                setSearchParams(tempSearchParams);
                            }}
                        >
                            {item.name}
                        </Button>
                    ))}
                </div>
            </TreatmentSelectModal>
            <SpecialtySelectModal>
                <div className="flex flex-col space-y-2">
                    <PageTitle>
                        진료과목 선택
                    </PageTitle>
                    {infoService.getSpecialties().map((item, index) => (
                        <Button
                            onButtonClicked={() => {
                                console.log("On Specialty Button Clicked", item);
                                closeSpecialtySelectModal();
                                const tempSearchParams = new URLSearchParams(searchParams);
                                tempSearchParams.delete("partId");
                                tempSearchParams.delete("concernId");
                                tempSearchParams.delete("treatmentId");
                                tempSearchParams.set("specialtyId", item.id);
                                setSearchParams(tempSearchParams);
                            }}
                        >
                            {item.name}
                        </Button>
                    ))}
                </div>
            </SpecialtySelectModal>
        </div>
    );
}

export default ContentsPage;
